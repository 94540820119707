import React from "react"
import { useIntl } from "react-intl"
import { useLocation } from "@reach/router"
import { css } from "@emotion/core"
import { Link } from "gatsby"

const LangSwitch = () => {
  const intl = useIntl()
  const lang = intl.locale
  const location = useLocation()

  return (
    <Link
      to={
        lang === "en"
          ? `/ar${location.pathname}`
          : location.pathname.replace("/ar", "")
      }
    >
      <span
        css={css`
          :lang(en) {
            font-family: Cairo;
          }
        `}
      >
        {lang === "en" ? `عربي` : "EN"}
      </span>
    </Link>
  )
}

export default LangSwitch
