import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { useIntl, FormattedMessage } from "react-intl"

const LocalizedLink = ({ to, textId, useActiveStyle }) => {
  const intl = useIntl()
  const localizedUrl = intl.locale === "en" ? to : `/${intl.locale}${to}`
  return (
    <Link
      to={localizedUrl}
      activeStyle={useActiveStyle && { color: "#157A10" }}
      partiallyActive={to !== "/"}
    >
      <FormattedMessage id={textId} />
    </Link>
  )
}

LocalizedLink.propTypes = {
  to: PropTypes.string.isRequired,
  textId: PropTypes.string.isRequired,
  useActiveStyle: PropTypes.bool,
}

LocalizedLink.defaultProps = {
  useActiveStyle: true,
}

export default LocalizedLink
