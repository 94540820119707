import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import { useIntl } from "react-intl"

import Header from "./header"
import "./layout.css"

const Layout = ({ children, title, galleryImage, closeUrl }) => {
  const intl = useIntl()
  const lang = intl.locale
  return (
    <div
      dir={lang === "ar" ? "rtl" : ""}
      lang={lang}
      css={css`
        height: 100vh;
      `}
    >
      <Header title={title} galleryImage={galleryImage} closeUrl={closeUrl} />
      <div
        css={css`
          margin: 0px auto;
          width: 100%;
          height: 100%;
          padding: 20px 50px;
        `}
      >
        <main>{children}</main>
        <footer />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  galleryImage: PropTypes.bool,
  closeUrl: PropTypes.string,
}

Layout.defaultProps = {
  galleryImage: false,
  closeUrl: "",
}

export default Layout
