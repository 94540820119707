import React, { useState } from "react"
import { css } from "@emotion/core"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { FormattedMessage } from "react-intl"
import Collapsible from "react-collapsible"
import { useMediaQuery } from "react-responsive"
import Menu from "./header/menu"

const Header = ({ title, galleryImage, closeUrl }) => {
  const [showMenu, setShowMenu] = useState(true)
  const isMobile = useMediaQuery({
    query: "(max-width: 640px)",
  })
  const menu = isMobile ? (
    <Collapsible open={showMenu} transitionTime={200}>
      <Menu />
    </Collapsible>
  ) : (
    <Menu />
  )

  return (
    <header
      dir="ltr"
      css={css`
        margin-bottom: 30px;
        padding: 20px 50px;
      `}
    >
      <nav
        css={css`
          display: flex;
          justify-content: space-between;
          padding: 20px 0px;
          @media (max-width: 640px) {
            flex-direction: column-reverse;
            margin: 0px auto;
            justify-content: center;
          }
        `}
      >
        <div
          css={css`
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 1px;
            white-space: nowrap;
            font-size: 39px;
            display: flex;
            justify-content: center;
          `}
        >
          <Link to="/">
            <FormattedMessage id="jordan" />
          </Link>
        </div>
        {!galleryImage && (
          <div
            css={css`
              display: none;
              justify-content: center;
              @media (max-width: 640px) {
                display: flex;
              }
            `}
          >
            <span
              role="presentation"
              css={css`
                font-size: 16px;
                text-transform: uppercase;
                cursor: pointer;
              `}
              onClick={() => setShowMenu(!showMenu)}
            >
              Menu
            </span>
          </div>
        )}
        {galleryImage ? (
          <Link to={closeUrl}>
            <div
              css={css`
                padding: 3px;
                float: right;
              `}
            >
              x
            </div>
          </Link>
        ) : (
          menu
        )}
      </nav>
      <div
        css={css`
          text-transform: uppercase;
          color: #737373;
        `}
      >
        {title}
      </div>
    </header>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  galleryImage: PropTypes.bool.isRequired,
  closeUrl: PropTypes.string.isRequired,
}

export default Header
