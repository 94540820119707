import React from "react"
import { css } from "@emotion/core"
import LangSwitch from "../menu/lang-switch"
import LocalizedLink from "../utils/localized-link"

const Menu = () => (
  <ul
    css={css`
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;

      li {
        padding: 0px 5px;
        align-self: center;

        a {
          // keep same fonts between switches
          color: black;
          text-transform: uppercase;

          :hover {
            color: #e6333f;
          }
        }
      }

      @media (max-width: 640px) {
        flex-direction: column;
      }
    `}
  >
    <li>
      <LocalizedLink to="/work-on-canvas" textId="menu.home" />
    </li>
    <li>
      <LocalizedLink to="/land" textId="menu.land" />
    </li>
    <li>
      <LocalizedLink to="/jaffa" textId="menu.jaffa" />
    </li>
    <li>
      <LocalizedLink to="/works" textId="menu.works" />
    </li>
    <li>
      <LocalizedLink
        to="/installation-views"
        textId="menu.installation-views"
      />
    </li>
    <li>
      <LocalizedLink to="/publications" textId="menu.publications" />
    </li>
    <li>
      <LocalizedLink to="/press" textId="menu.press" />
    </li>
    <li>
      <LocalizedLink to="/cv" textId="menu.cv" />
    </li>
    <li>
      <LocalizedLink to="/about" textId="menu.about" />
    </li>
    <li>
      <LangSwitch />
    </li>
  </ul>
)

export default Menu
